import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import LogoUrl from '../../../../resources/logo.svg';
import FullscreenIconUrl from '../../../../resources/icons/icon-fullscreen.svg';
import ToolsIconUrl from '../../../../resources/icons/icon-tools.svg';
import ZoomIconUrl from '../../../../resources/icons/icon-zoom.svg';
import DownloadIconUrl from '../../../../resources/icons/icon-download-white.svg';
import FacebookIconUrl from '../../../../resources/icons/icon-facebook.svg';
import XIconUrl from '../../../../resources/icons/icon-x.svg';
import YoutubeIconUrl from '../../../../resources/icons/icon-youtube.svg';
import InstagramIconUrl from '../../../../resources/icons/icon-instagram.svg';
import LinkedinIconUrl from '../../../../resources/icons/icon-linkedin.svg';
import FAIconUrl from '../../../../resources/icons/icon-fa.svg';
import { getIcon } from '../index';
import ShareButton from './ShareButton';

import {
  WEBSITE_URL,
  FACEBOOK_URL,
  FA_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  X_URL,
  YOUTUBE_URL,
  MENU_HIERARCHY,
  MENU_MOBILE_FOOTER_HEIGHT
} from '../../../../config';

import styles from '../styles';
import parse from '../../../../utils/parse';

class MobileAppMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentHighlightedItem: null,
      swipeMenuOpen: false,
    };
  }
  /**
   * HANDLE
   */
  handleNavigate(page) {
    const { onNavigate } = this.props;
    this.setState({ swipeMenuOpen: false });
    onNavigate(page);
  }
  /**
   * RENDER
   */
  renderHeader() {
    const { classes, onToggleFullscreen, onZoom } = this.props;
    return (
      <AppBar
        className={classes.mobileMenuHeader}
        elevation={0}
        color="inherit"
        position="fixed"
      >
        <Toolbar disableGutters>
          <Stack sx={{ width: '100%', height: '100%' }} direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
              <img src={LogoUrl} alt="Campus France" />
            </a>
            <Box sx={{ flex: 1 }}></Box>
            <IconButton onClick={() => onToggleFullscreen(true)}>
              <img className={classes.menuIcon} src={FullscreenIconUrl} alt="Plein écran" />
            </IconButton>
            <IconButton onClick={() => onZoom()}>
              <img className={classes.menuIcon} src={ZoomIconUrl} alt="Zoom" />
            </IconButton>
            <IconButton onClick={() => this.setState({ currentHighlightedItem: 'MENU', swipeMenuOpen: true })}>
              <img className={classes.menuIcon} src={ToolsIconUrl} alt="Menu" />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }

  renderFooter(){
    const { classes, page, fileURL } = this.props;
    const { currentHighlightedItem, swipeMenuOpen } = this.state;

    const color = (currentHighlightedItem && currentHighlightedItem.color) || 'darkblue';

    return (
      <div className={`${classes.mobileMenuFooter} ${swipeMenuOpen ? 'swipeMenuOpen' : ''} ${color}`}>
        <SwipeableDrawer
          anchor="bottom"
          open={swipeMenuOpen}
          onClose={() => this.setState({ swipeMenuOpen: false })}
          onOpen={() => { }}
          swipeAreaWidth={MENU_MOBILE_FOOTER_HEIGHT}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            className: `${classes.mobileMenuSwipe} ${classes.subDrawer} ${color}`,
            elevation: 0,
          }}
        >
          <Paper 
            className={classes.mobileMenuSwipePaper} 
            elevation={0} 
            sx={{ backgroundColor: currentHighlightedItem && currentHighlightedItem !== 'MENU' ? '' : `#e1000f !important` }}
            >
            <Box className={classes.mobileMenuPuller} />
            {currentHighlightedItem && currentHighlightedItem !== 'MENU' ? (
              <>
                <Typography variant="h2" color='magenta.light'>{parse(currentHighlightedItem.focusLabel)}</Typography>
                <Box sx={{ flex: 1 }}></Box>
                <List>
                  {currentHighlightedItem.list.map(bookmark => {
                    const isFocus = page >= bookmark.pages[0] && page <= bookmark.pages[1];
                    return (
                      <ListItem key={bookmark.label} className={`${classes.subListItem} ${currentHighlightedItem.color} ${isFocus ? 'focus' : ''}`} disablePadding>
                        <ListItemButton onClick={() => this.handleNavigate(bookmark.pages[0])}>
                          <ListItemText
                            className={`${classes.subListItemLabel} ${currentHighlightedItem.color}`}
                            primary={<Typography variant="h4">{parse(bookmark.label)}</Typography>}
                            secondary={<span className={`listItemFocus ${currentHighlightedItem.color}`}></span>}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : (
              <>
                <Stack sx={{ mb: 2, mt: 2 }} direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <ShareButton classes={classes} isMobile />
                    <ListItem key="download" className={classes.listItem} disablePadding sx={{ justifyContent: 'center' }}>
                    <ListItemButton
                      className={classes.shareAndDownloadMobileButton}
                      sx={{ flexGrow: 0 }}
                      onClick={() => {
                        // Event for Downloads stat with analytics
                        window.gtag('event', 'download_pdf', {
                          'event_category': 'Campus - RA 2023',
                          'event_label': 'download_pdf_click'
                        });
                        const link = document.createElement("a");
                        link.href = fileURL;
                        link.download = 'Campus France - Rapport annuel 2023.pdf';
                        link.click();
                      }}
                    >
                      <ListItemIcon>
                        <img className={classes.menuIcon} src={DownloadIconUrl} alt="Télécharger le rapport" />
                      </ListItemIcon>
                      <ListItemText className={classes.drawerLabel} primary={<Typography variant="h4">Télécharger le rapport</Typography>} />
                    </ListItemButton>
                  </ListItem>
                  <Stack className={classes.socialListMobile} direction="row" justifyContent="space-evenly" spacing={0.5}>
                      <IconButton key="facebook" size="small" onClick={() => window.open(FACEBOOK_URL, '_blank')}>
                        <img src={FacebookIconUrl} alt="X" />
                      </IconButton>
                      <IconButton key="fa" size="small" onClick={() => window.open(FA_URL, '_blank')}>
                        <img src={FAIconUrl} alt="X" />
                      </IconButton>
                      <IconButton key="instagram" size="small" onClick={() => window.open(INSTAGRAM_URL, '_blank')}>
                        <img src={InstagramIconUrl} alt="X" />
                      </IconButton>
                      <IconButton key="linkedin" size="small" onClick={() => window.open(LINKEDIN_URL, '_blank')}>
                        <img src={LinkedinIconUrl} alt="Linkedin" />
                      </IconButton>
                      <IconButton key="x" size="small" onClick={() => window.open(X_URL, '_blank')}>
                        <img src={XIconUrl} alt="X" />
                      </IconButton>
                      <IconButton key="youtube" size="small" onClick={() => window.open(YOUTUBE_URL, '_blank')}>
                        <img src={YoutubeIconUrl} alt="Youtube" />
                      </IconButton>
                  </Stack>
                </Stack>
              </>
            )}
          </Paper>
        </SwipeableDrawer>
        <Stack className={classes.mobileMenuFooterContent} direction="row" alignItems="flex-end" justifyContent="space-evenly" spacing={2}>
          {MENU_HIERARCHY.map(item => {
            const isFocus = page >= item.pages[0] && page <= item.pages[1];
            const isMenuOpen = swipeMenuOpen && item === currentHighlightedItem;
            return (
              <span key={item.label} className={`${classes.mobileMenuButtonWrapper} ${isFocus ? 'focus' : ''} ${item.color}`}>
                <IconButton
                  className={`${classes.mobileMenuButton} ${isMenuOpen ? 'menuOpen' : ''} ${item.color}`}
                  color="inherit"
                  size="large"
                  onClick={() => this.setState({ currentHighlightedItem: item, swipeMenuOpen: true })}
                >
                  <img className={classes.menuIcon} src={getIcon(item.icon)} alt={item.label} />
                </IconButton>
              </span>
            );
          })}
        </Stack>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderFooter()}
      </>
    );
  }
}

MobileAppMenu.propTypes = {
  // props
  categoryOpen: PropTypes.string,
  // funcs
  onNavigate: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
}

export default withStyles(styles)(MobileAppMenu);
