import React from 'react';
import isNil from 'lodash/isNil';
import { withStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import debounce from 'lodash/debounce';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import LogoUrl from '../../resources/logo-white.svg';

import AppMenu from './AppMenu';
import Main from '../Main';

import Device from '../../utils/Device';
import fetch from '../../utils/Fetch';

import { 
  MENU_DESKTOP_WIDTH, 
  MENU_MOBILE_FOOTER_HEIGHT, 
  MENU_MOBILE_HEADER_HEIGHT,
  PDF_PAGE_RATIO,
  NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
  PDF_NAME, 
  SKIP_ANIMATION_STORAGE_KEY 
} from '../../config';
import Theme from '../../theme';
import styles from './styles';

/**
 * ANIMATION STATES
  'loading',
  'loadingFade',
  'splashWait',
  'splashscreen',
  'splashFade',
  'end'
 */

class App extends React.Component {
  constructor(props) {
    super(props);

    const isMobile = !isNil(Device.mobile());
    this.state = {
      isMobile,

      loading: false,
      progress: 0,
      animationState: 0,
      skipAnimation: window.sessionStorage.getItem(SKIP_ANIMATION_STORAGE_KEY) === 'TRUE',

      fileURL: null,
      page: 1,
      pageMax: 1,
      renderingPageLeft: true,
      renderingPageRight: true,

      displayType: isMobile ? 'single' : 'double',
      fullscreen: false,
      width: 360,
      height: 540,
      zoomLevel: 0,
    };

    this.onWindowResize = debounce(this.onWindowResize.bind(this), 120);
    this.startSlashscreen = this.startSlashscreen.bind(this);
    this.onFileProgress = this.onFileProgress.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleToggleFullscreen = this.handleToggleFullscreen.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleLoadSuccess = this.handleLoadSuccess.bind(this);

    this.mount = false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);

    if (!this.mount) {
      this.mount = true;
      this.preloadFile();
      this.onWindowResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  async preloadFile() {
    if (!this.state.loading) {
      this.setState({ loading: true }, async () => {
        const blob = await fetch(PDF_NAME, this.onFileProgress);
        const fileURL = URL.createObjectURL(blob);
        setTimeout(() => {
          this.setState({
            loading: false,
            fileURL,
            animationState: 1,
          }, this.startSlashscreen);
        }, 1000);
      });
    }
  }

  onFileProgress(progress) {
    this.setState({ progress });
  }

  startSlashscreen() {
    setTimeout(() => {
      if (this.state.skipAnimation) {
        this.setState({ animationState: 5 });
      } else {
        this.setState({ animationState: 2 }, () => {
            setTimeout(() => {
              this.setState({ animationState: 3 }, () => {
                setTimeout(() => {
                  this.setState({ animationState: 4 }, () => {
                    setTimeout(() => {
                      window.sessionStorage.setItem(SKIP_ANIMATION_STORAGE_KEY, 'TRUE');
                      this.setState({ animationState: 5 });
                    }, 1000);
                  });
                }, 3500);
              });
            }, 200);
          });
      }
    }, 1000);
  }

  /**
   * HANDLE
   */
  onWindowResize(evt) {
    this.setState(prev => {
      const { isMobile, fullscreen, page, pageMax, displayType } = prev;
      return this.getViewPort(isMobile, fullscreen, page, pageMax, displayType);
    });
  }

  getViewPort(isMobile, fullscreen, page, pageMax, prevDisplayType) {
    let displayType = prevDisplayType;
    let width, height;

    let viewPortWidth = window.innerWidth;
    let viewPortHeight = window.innerHeight;
    const forceSingle = page === 1 || (page === pageMax && pageMax % 2 === 0);

    if (isMobile) {
      displayType = 'single';
      if (!fullscreen) {
        const menuHeight = (MENU_MOBILE_HEADER_HEIGHT + MENU_MOBILE_FOOTER_HEIGHT);
        viewPortWidth -= 16;
        // viewPortHeight -= menuHeight + NAVIGATION_HEIGHT + 64;
        viewPortHeight -= menuHeight + MOBILE_NAVIGATION_HEIGHT;
      }
      const viewPortRatio = viewPortWidth / viewPortHeight;
      if (!forceSingle && viewPortRatio >= PDF_PAGE_RATIO * 2) {
        displayType = 'double';
        height = viewPortHeight;
        width = height * PDF_PAGE_RATIO;
      } else if (viewPortRatio >= PDF_PAGE_RATIO) {
        height = viewPortHeight;
        width = height * PDF_PAGE_RATIO;
      } else {
        width = viewPortWidth;
        height = width / PDF_PAGE_RATIO;
      }
    } else {
      if (!fullscreen) {
        viewPortWidth -= MENU_DESKTOP_WIDTH + 64;
        viewPortHeight -= NAVIGATION_HEIGHT + 64;
      }
      const viewPortRatio = viewPortWidth / viewPortHeight;
      if (!forceSingle && viewPortRatio >= PDF_PAGE_RATIO * 2) {
        displayType = 'double';
        height = viewPortHeight;
        width = height * PDF_PAGE_RATIO;
      } else if (!forceSingle && viewPortRatio >= PDF_PAGE_RATIO * 1.5) {
        displayType = 'double';
        width = viewPortWidth / 2;
        height = width / PDF_PAGE_RATIO;
      } else if (viewPortRatio <= PDF_PAGE_RATIO) {
        displayType = 'single';
        width = viewPortWidth;
        height = width / PDF_PAGE_RATIO;
      } else {
        displayType = 'single';
        height = viewPortHeight;
        width = height * PDF_PAGE_RATIO;
      }
    }
    return {
      width: Math.floor(width),
      height: Math.floor(height),
      displayType,
    };
  }

  handleNavigate(page) {
    this.setState(prev => {
      const { isMobile, fullscreen, pageMax, displayType: prevDisplayType} = prev;
      const { displayType } = this.getViewPort(isMobile, fullscreen, page, pageMax, prevDisplayType);
      if (prev.page !== page) return { page, renderingPageLeft: true, renderingPageRight: true, displayType };
    });
  }

  handleToggleFullscreen(fullscreen)   {
    this.setState({ fullscreen }, this.onWindowResize);
  }

  handleZoom(params, evt = null) {
    let x = 0;
    let y = 0;
    if (evt) {
      const rect = evt.currentTarget.getBoundingClientRect();
      x = evt.clientX - rect.left;
      y = evt.clientY - rect.top;
    }

    const { setTransform, resetTransform, centerView } = params;
    this.setState(prev => {
      let zoomLevel = prev.zoomLevel + 1;
      if (zoomLevel > 2) {
        resetTransform();
        zoomLevel = 0;
      } else {
        const zoom = 1 + (zoomLevel * 0.7);
        if (evt) {
          setTransform(-x, -y, zoom);
        } else {
          centerView(zoom);
        }
      }
      return { zoomLevel };
    });
  }

  handleLoadSuccess({ numPages })   {
    this.setState({ pageMax: numPages });
  }

  /**
   * RENDER
   */
  renderLoader() {
    const { classes } = this.props;
    const { progress, animationState } = this.state;
    return (
      <Stack className={`${classes.loader} ${animationState > 0 ? 'fade' : ''}`} sx={{ height: '100vh' }} alignItems="center" justifyContent="center">
        <Stack sx={{ width: 220 }} justifyContent="center" spacing={2}>
          <img src={LogoUrl} alt="Logo Campus France" />
          <LinearProgress color="secondary" variant="determinate" value={progress * 100} />
        </Stack>
      </Stack>
    )
  }

  renderSplashscreen() {
    const { classes } = this.props;
    const { isMobile, animationState } = this.state;
    return (
      <Stack className={`${classes.splashscreen} ${animationState === 3 ? 'show' : ''} ${animationState === 4 ? 'end' : ''}`} sx={{ height: '100vh' }} alignItems="center" justifyContent="center">
        <img src={`splashscreen${isMobile ? '-mobile' : ''}.png`} alt="Campus France" />
      </Stack>
    );
  }

  renderApp() {
    const { classes } = this.props;
    const { 
      isMobile,
      fileURL, 
      page, 
      pageMax, 
      displayType, 
      zoomLevel, 
      fullscreen, 
      width, 
      height,
      renderingPageLeft,
      renderingPageRight,
    } = this.state;
    return (
      <Box className={classes.content}>
        <TransformWrapper
          className="transformWrapper"
          wheel={{ disabled: true }}
          doubleClick={{ disabled: true }}
          centerOnInit
        >
          {transformParams => (
            <>
              <AppMenu
                isMobile={isMobile}
                page={page}
                fullscreen={fullscreen}
                fileURL={fileURL}
                onNavigate={this.handleNavigate}
                onToggleFullscreen={this.handleToggleFullscreen}
                onZoom={() => this.handleZoom(transformParams)}
              />
              <Main
                isMobile={isMobile}
                fileURL={fileURL}
                displayType={displayType}
                zoomLevel={zoomLevel}
                fullscreen={fullscreen}
                width={width}
                height={height}
                page={page}
                pageMax={pageMax}
                renderingPageLeft={renderingPageLeft}
                renderingPageRight={renderingPageRight}
                transformParams={transformParams}
                onNavigate={this.handleNavigate}
                onLoadSuccess={this.handleLoadSuccess}
                onToggleFullscreen={this.handleToggleFullscreen}
                onZoom={evt => this.handleZoom(transformParams, evt)}
                onRenderSuccess={field => this.setState({ [field]: false })}
              />
            </>
          )}
        </TransformWrapper>
      </Box>
    );
  }

  render() {
    const { classes } = this.props;
    const { isMobile, animationState, skipAnimation, fullscreen } = this.state;

    return (
      <ThemeProvider theme={Theme} >
        <CssBaseline />
        <div className={`${classes.container} ${animationState < 4 ? 'startup' : ''} ${isMobile ? 'mobile' : 'desktop'} ${fullscreen ? 'fullscreen' : ''}`}>
          {animationState < 3 && this.renderLoader()}
          {!skipAnimation && animationState >= 1 && animationState < 5 && this.renderSplashscreen()}
          {animationState >= 1 && this.renderApp()}
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
