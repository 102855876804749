import Theme from '../../theme';

export default ({
  container: {
    width: '100vw',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: Theme.palette.white.dark,
    transition: 'background-color 1000ms',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  loader: {
    width: '100%',
    opacity: 1,
    transition: 'opacity 1000ms',
    backgroundColor: Theme.palette.darkblue.main,
    position: 'absolute',
    top: 0,
    zIndex: 1602,

    '&.fade': {
      opacity: 0,
    },
  },
  splashscreen: {
    width: '100%',
    opacity: 1,
    position: 'absolute',
    top: 0,
    zIndex: 1601,
    backgroundColor: Theme.palette.darkblue.main,
    transition: 'opacity 1000ms',

    '&.show img': {
      opacity: 1,
    },
    '&.end': {
      opacity: 0,
    },
    '& img': {
      width: '100%',
      objectFit: 'contain',
      opacity: 0,
      transition: 'opacity 1000ms',
    },
  },
});
