export const PDF_NAME = 'CAMP-RA_Forum2023-WEB.pdf';

export const SKIP_ANIMATION_STORAGE_KEY = 'SKIP_ANIMATION';

export const MENU_HIERARCHY = [
  {
    label: 'ÉDITORIAL',
    focusLabel: 'ÉDITORIAL',
    icon: 'icon-1.svg',
    color: 'darkblue',
    pages: [2, 3],
    list: [
      { label: 'Édito', pages: [2, 3] },
    ],
  },
  {
    label: 'LE FORUM CAMPUS FRANCE',
    focusLabel: 'LE FORUM CAMPUS FRANCE',
    icon: 'icon-2.svg',
    color: 'darkblue',
    pages: [4, 5],
    list: [
      { label: 'Le forum Campus France', pages: [4, 5] },
    ],
  },
  {
    label: 'FONCTIONNEMENT DU FORUM',
    focusLabel: 'FONCTIONNEMENT DU FORUM',
    icon: 'icon-3.svg',
    color: 'darkblue',
    pages: [6, 7],
    list: [
      { label: 'Fonctionnement du forum', pages: [6, 7] },
    ],
  },
  {
    label: '2022-2023 :<br>QUELQUES TEMPS FORTS&nbsp;!',
    focusLabel: '2022-2023 : QUELQUES TEMPS FORTS&nbsp;!',
    icon: 'icon-4.svg',
    color: 'darkblue',
    pages: [8, 17],
    list: [
      { label: '2022', pages: [8, 11] },
      { label: '2023', pages: [12, 17] },
    ],
  },
  {
    label: 'COMMISSIONS 2022-2023 :<br>QUE RETENIR&nbsp;?',
    focusLabel: 'COMMISSIONS 2022-2023 : QUE RETENIR&nbsp;?',
    icon: 'icon-5.svg',
    color: 'darkblue',
    pages: [18,27],
    list: [
      { label: 'Commissions 2022-2023 :<br>Que retenir&nbsp;?', pages: [18, 18] },
      { label: 'Commission<br>Asie Indo-Pacifique', pages: [19, 19] },
      { label: 'Commission Europe', pages: [20, 21] },
      { label: 'Commission Recherche et Formation Doctorale', pages: [22, 23] },
      { label: 'Commissions<br>DDRS et Transitions', pages: [24, 25] },
      { label: 'Commission 2022-2023&nbsp;: Composition', pages: [26, 27] },
    ],
  },
];

export const MENU_DESKTOP_WIDTH = 300;
export const MENU_MOBILE_HEADER_HEIGHT = 85;
export const MENU_MOBILE_FOOTER_HEIGHT = 75;
export const NAVIGATION_HEIGHT = 52;
export const MOBILE_NAVIGATION_HEIGHT = 40;
// export const PDF_PAGE_RATIO = 750 / 1013;
export const PDF_PAGE_RATIO = 209 / 297;

const SHARE_URL = encodeURIComponent(window.location.href);
const SHARE_DESCRIPTION = encodeURI('Lisez le rapport annuel 2023 de l\'Campus France');
const SHARE_TITLE = encodeURI('Rapport annuel 2023 - Campus France');
export const FACEBOOK_SHARE_PARAMS = `http://www.facebook.com/share.php?u=${SHARE_URL}`;
export const X_SHARE_PARAMS = `http://x.com/intent/tweet?text=${SHARE_DESCRIPTION}&url=${SHARE_URL}`;
export const LINKEDIN_SHARE_PARAMS = ` https://www.linkedin.com/shareArticle?mini=true&url=${SHARE_URL}&title=${SHARE_TITLE}&summary=${SHARE_DESCRIPTION}`;

export const WEBSITE_URL = 'https://www.campusfrance.org/fr';
export const FACEBOOK_URL = 'https://fr-fr.facebook.com/CampusFranceParis/';
export const FA_URL = 'https://www.francealumni.fr/fr';
export const INSTAGRAM_URL = 'https://www.instagram.com/campus_france/';
export const LINKEDIN_URL = 'https://fr.linkedin.com/company/campusfrance';
export const X_URL = 'https://x.com/campusfrance?lang=fr';
export const YOUTUBE_URL = 'https://www.youtube.com/user/ParisCampusFrance';

const CONFIG = {
  PDF_NAME,
  SKIP_ANIMATION_STORAGE_KEY,
  MENU_HIERARCHY,
  MENU_DESKTOP_WIDTH,
  MENU_MOBILE_HEADER_HEIGHT,
  MENU_MOBILE_FOOTER_HEIGHT,
  PDF_PAGE_RATIO,
  NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
  FACEBOOK_SHARE_PARAMS,
  X_SHARE_PARAMS,
  WEBSITE_URL,
  FACEBOOK_URL,
  FA_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  YOUTUBE_URL,
  X_URL,
};

export default CONFIG;
