import Theme from '../../theme';
import { alpha } from '@mui/material/styles';
import { MENU_MOBILE_HEADER_HEIGHT, MENU_MOBILE_FOOTER_HEIGHT } from '../../config';

export default ({
  main: {
    width: '100%',
    height: '100%',
    flex: 1,
    padding: Theme.spacing(4),
    boxSizing: 'border-box',
    '.mobile &': {
      paddingTop: `calc(${MENU_MOBILE_HEADER_HEIGHT}px + ${Theme.spacing(1)})`,
      paddingBottom: `calc(${MENU_MOBILE_FOOTER_HEIGHT}px + ${Theme.spacing(1)})`,
      paddingLeft: Theme.spacing(1),
      paddingRight: Theme.spacing(1),
    },
    '.fullscreen &': {
      padding: 0,
    },
  },
  transformWrapper: {
    width: '100%',
    height: 'calc(100% - 52px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',

    '&.fullscreen': {
      height: '100%',
    },
  },
  fileContainer: {
    width: '100%',
    height: '100%',
    '&:hover': {
      cursor: 'grab',
    },
    '&:active': {
      cursor: 'grabbing',
    }
  },
  fileDocument: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    '& canvas': {
      transform: 'scale(0.5)',
      transformOrigin: '0 0',
    },
    '& .react-pdf__Page__annotations section': {
      position: 'absolute',
      ' & a': {
        width: '100%',
        height: '100%',
        display: 'inline-block',
      },
    },
  },
  documentWrapper: {
    position: 'relative',
  },
  documentLoading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    opacity: 1,
    backgroundColor: Theme.palette.white.dark,
    transition: 'opacity 0ms',
    
    '&.hide': {
      zIndex: 0,
      opacity: 0,
      transition: 'opacity 200ms, z-index 0ms 200ms',
    }
  },
  navigation: {
    marginTop: Theme.spacing(2),
  },
  navigationButtonContainer: {
    width: 220,
    '& .MuiButtonBase-root.Mui-disabled': {
      opacity: 0.5,
    },
  },
  navigationProgressBar: {
    height: '12px !important',
    padding: '2px 0 !important',
    flex: 1,
    '& > .MuiSlider-rail': {
      backgroundColor: Theme.palette.white.main,
    },
    '& > .MuiSlider-track': {
      backgroundColor: Theme.palette.primary.light,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& > .MuiSlider-mark:not(.MuiSlider-markActive)': {
      backgroundColor: Theme.palette.primary.main,
    },
    '& > .MuiSlider-thumb': {
      backgroundColor: Theme.palette.primary.main,
      '&.Mui-active': {
        boxShadow: `0px 0px 0px 16px ${alpha(Theme.palette.primary.light, 0.2)}`,
      },
      '&.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${alpha(Theme.palette.primary.light, 0.2)}`,
      },
      '&:hover': {
        boxShadow: `0px 0px 0px 8px ${alpha(Theme.palette.primary.light, 0.2)}`,
      }
    },
  },
  fullscreenSingleButton: {
    position: 'fixed !important',
    right: 45,
    bottom: 45,
    zIndex: 9,
    '& .MuiButtonBase-root': {
      width: 44,
      backgroundColor: `${alpha(Theme.palette.white.main, 0.3)} !important`,
      
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  },
  menuIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
});
