import React from 'react';
import PropTypes from 'prop-types';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

import Icon1Url from '../../../resources/icons/icon-1.svg';
import Icon2Url from '../../../resources/icons/icon-2.svg';
import Icon3Url from '../../../resources/icons/icon-3.svg';
import Icon4Url from '../../../resources/icons/icon-4.svg';
import Icon5Url from '../../../resources/icons/icon-5.svg';

export function getIcon(name) {
  switch(name) {
    default:
    case 'icon-1.svg':
      return Icon1Url;
    case 'icon-2.svg':
      return Icon2Url;
    case 'icon-3.svg':
      return Icon3Url;
    case 'icon-4.svg':
      return Icon4Url;
    case 'icon-5.svg':
      return Icon5Url;
  }
}

class AppMenuWrapper extends React.Component {
  render() {
    if (this.props.isMobile) {
      return <MobileMenu {...this.props} />;
    }
    return <DesktopMenu {...this.props} />;
  }
}

AppMenuWrapper.defaultProps = {
  page: 0,
};

AppMenuWrapper.propTypes = {
  // props
  isMobile: PropTypes.bool,
  fullscreen: PropTypes.bool,
  page: PropTypes.number,
  // funcs
  onNavigate: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
};

export default AppMenuWrapper;
