import Theme from '../../../theme';
import { alpha } from '@mui/material/styles';
import { MENU_DESKTOP_WIDTH, MENU_MOBILE_HEADER_HEIGHT, MENU_MOBILE_FOOTER_HEIGHT } from '../../../config';
import theme from '../../../theme';

const colors = ['darkblue', 'pink', 'magenta'];
function loopColor(fields, selectorPrefix = '&.', selectorSuffix = '') {
  const output = {};
  colors.forEach(color => {
    Object.keys(fields).forEach(key => {
      let colorParam = fields[key];
      let customAlpha = 1;
      if (typeof fields[key] === 'object') {
        colorParam = fields[key].color;
        if (fields[key].alpha) {
          customAlpha = fields[key].alpha;
        }
      }
      let value = Theme.palette[color][colorParam];
      if (customAlpha !== 1) {
        value = alpha(Theme.palette[color][colorParam], customAlpha);
      }
      output[`${selectorPrefix}${color}${selectorSuffix}`] = {
        [key]: value
      };
    });
  });
  return output;
}

export default ({
  desktopMenuContainer: {
    height: '100%',
  },
  mainDrawer: {
    width: MENU_DESKTOP_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: MENU_DESKTOP_WIDTH,
      boxSizing: 'border-box',
      border: 'none',
      overflow: 'hidden',
      justifyContent: 'space-between',
      zIndex: 9,
    },
  },
  mainDrawerHeader: {
    padding: Theme.spacing(4),
    '& img': {
      width: '75%',
      objectFit: 'contain',
    },
  },
  listItem: {
    transition: 'background-color 500ms',
    color: Theme.palette.darkblue.main,
    ...loopColor({ color: 'main' }),
    '&::after': {
      content: '""',
      width: 10,
      height: '100%',
      position: 'absolute',
      right: 0,
      transition: 'background-color 250ms',
      pointerEvents: 'none',
    },
    '&.focus': {
      '&.darkblue': {
        backgroundColor: Theme.palette.magenta.ultralight,
        '&::after': {
          backgroundColor: Theme.palette.magenta.light,
        },
      },
      '&.pink': {
        backgroundColor: Theme.palette.pink.ultralight,
        '&::after': {
          backgroundColor: Theme.palette.pink.light,
        },
      },
    },
    '& .MuiListItemButton-root': {
      paddingLeft: Theme.spacing(5),
      '& .MuiListItemIcon-root': {
        alignSelf: 'flex-start',
        marginTop: 5,
      },
    },
  },
  drawerLabel: {
    '&.category': {
      textTransform: 'uppercase',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  whiteLabel: {
    color: `${Theme.palette.white.main} !important`,
  },
  listItemEndButton: {
    width: 200,
    borderBottomLeftRadius: '30px !important',
    borderBottomRightRadius: '30px !important',
    borderTopLeftRadius: '30px !important',
    borderTopRightRadius: '30px !important',
    border: 'none !important',
    backgroundColor: `${Theme.palette.darkblue.main} !important`,
    color: `${Theme.palette.white.contrastText} !important`,
    '&:hover': {
      backgroundColor: `${Theme.palette.darkblue.light} !important`,
    },
    '& .MuiTypography-h2': {
      fontSize: '27 !important',
      lineHeight: '37px !important',
      color: 'inherit !important',
    },
  },
  subDrawerContainer: {
    width: `calc(${MENU_DESKTOP_WIDTH}px + 20px)`,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: `calc(${MENU_DESKTOP_WIDTH}px + 20px)`,
      left: `calc(${MENU_DESKTOP_WIDTH}px - 20px)`,
      paddingLeft: 20,
      boxSizing: 'border-box',
      border: 'none',
      overflow: 'hidden',
      zIndex: 8,
      transition: 'transform 225ms, background-color 120ms !important',
    },
  },
  subDrawer: {
    '& .MuiPaper-root': {
      backgroundColor: alpha(Theme.palette.darkblue.dark, 0.95),
    },
  },
  subDrawerPaper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    "& ::-webkit-scrollbar": {
      width: 8,
      height: 10,
    },
    "& ::-webkit-scrollbar-thumb": {
      background: Theme.palette.darkblue.light,
      borderRadius: 5,
      height: '10px',
      border: '2px solid transparent',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${Theme.palette.darkblue.light} transparent`,
    '& .MuiTypography-h2': {
      padding: Theme.spacing(4),
      paddingTop: Theme.spacing(6),
    },
    '& > .MuiList-root': {
      overflowY: 'auto',
      '& .MuiListItem-root:last-child': {
        paddingBottom: Theme.spacing(6),
      },
    },
  },
  subList: {
    overflow: 'auto',
  },
  subListItem: {
    '&::after': {
      content: '""',
      width: 10,
      height: '100%',
      position: 'absolute',
      right: 0,
      transition: 'background-color 250ms',
      pointerEvents: 'none',
    },
    color: Theme.palette.white.main,
    '&.focus': {
      '&.darkblue': {
        color: Theme.palette.magenta.light,
        backgroundColor: alpha(Theme.palette.magenta.ultralight, 0.075),
        '&::after': {
          backgroundColor: Theme.palette.magenta.light,
        },
      },
      '&.pink': {
        color: Theme.palette.pink.light,
        backgroundColor: alpha(Theme.palette.pink.ultralight, 0.075),
        '&::after': {
          backgroundColor: Theme.palette.pink.light,
        },
      },
    },
    '& > .MuiListItemButton-root': {
      paddingLeft: Theme.spacing(6),
    }
  },
  socialList: {
    width: '100%', 
    marginTop: theme.spacing(4),
    '& img': {
      width: 25,
      height: 25
    }
  },
  socialListMobile: {
    width: '80%',
    margin: 'auto',
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(1),
    '& img': {
      width: 35,
      height: 35
    }
  },
  menuIconWrapper: {
    minWidth: '30px !important',
  },
  menuIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
  sharePopper: {
    zIndex: 9,
    '& .MuiTypography-root': {
      color: Theme.palette.darkblue.main,
    },
  },
  mobileMenuHeader: {
    height: MENU_MOBILE_HEADER_HEIGHT,
    padding: Theme.spacing(2, 4, 2),
    backgroundColor: Theme.palette.white.main,
    transition: 'top 250ms !important',

    '& img': {
      maxWidth: 200,
      height: 32,
      objectFit: 'contain',
    },

    '.fullscreen &': {
      top: `-${MENU_MOBILE_HEADER_HEIGHT}px`,
    },
  },
  mobileMenuFooter: {
    width: '100%',
    height: MENU_MOBILE_FOOTER_HEIGHT,
    padding: Theme.spacing(1, 2, 0),
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    backgroundColor: Theme.palette.white.main,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    transition: 'bottom 250ms !important',
    overflow: 'hidden',
    zIndex: 1300,

    '.fullscreen &': {
      bottom: `-${MENU_MOBILE_FOOTER_HEIGHT}px`,
    },
  },
  mobileMenuFooterContent: {
    width: '100%',
    height: '100%',
  },
  mobileMenuButtonWrapper: {
    ...loopColor({ color: 'main' }),
    paddingBottom: Theme.spacing(2),
    position: 'relative',

    '&::after': {
      content: '""',
      width: '100%',
      height: 10,
      position: 'absolute',
      left: 0,
      bottom: 0,
      transition: 'background-color 250ms',
      pointerEvents: 'none',
    },
    '&.focus': {
      '&.darkblue': {
        color: Theme.palette.darkblue.light,
        '&::after': {
          backgroundColor: Theme.palette.magenta.light,
        },
      },
      '&.pink': {
        color: Theme.palette.pink.light,
        '&::after': {
          backgroundColor: Theme.palette.pink.light,
        },
      },
    },
  },
  mobileMenuButton: {
    position: 'relative',
    bottom: 0,
    transition: 'background-color 250ms, bottom 250ms !important',
    
    '&.menuOpen': {
      bottom: Theme.spacing(1),
    },
  }, 
  mobileMenuSwipe: {
    overflow: 'hidden',
    
    '& .MuiTypography-h2': {
      paddingLeft: Theme.spacing(4),
      paddingRight: Theme.spacing(4),
      fontSize: 26,
      lineHeight: '32px',
    },
    '& .MuiTypography-h4': {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  mobileMenuSwipePaper: {
    paddingTop: Theme.spacing(4),
    paddingBottom: `calc(${MENU_MOBILE_FOOTER_HEIGHT}px + 5px)`,
    boxSizing: 'border-box',
  },
  mobileMenuPuller: {
    width: 30,
    height: 6,
    backgroundColor: Theme.palette.white.dark,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  },
  shareAndDownloadMobileButton: {
    paddingLeft: `12px !important`,
    '& .MuiTypography-root': {
      color: Theme.palette.white.main,
    },
    '& .MuiListItemIcon-root': {
      minWidth: '35px !important',
    },
  },
});
