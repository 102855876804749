import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';

import PrevIconUrl from '../../resources/icons/icon-prev.svg';
import NextIconUrl from '../../resources/icons/icon-next.svg';
import ZoomIconUrl from '../../resources/icons/icon-zoom.svg';
import FullscreenIconUrl from '../../resources/icons/icon-fullscreen.svg';
import FullscreenCloseIconUrl from '../../resources/icons/icon-fullscreen-close.svg';

import Device from '../../utils/Device';

import { MENU_HIERARCHY } from '../../config';

const MARKS = MENU_HIERARCHY.map(item => ({
  value: item.pages[0],
}));

class DocumentNavigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener('keyup', this.onKeyUp);
  }

  /**
   * HANDLE
   */
  onKeyUp(evt) {
    if (evt.code === 'ArrowRight') {
      this.handleNext();
    } else if(evt.code === 'ArrowLeft') {
      this.handlePrev();
    }
  }

  handleNext() {
    const { page, pageMax, displayType, onNavigate } = this.props;
    const addition = page > 1 && displayType === 'double' ? 2 : 1;
    let nextPage = page + addition;
    if (nextPage > pageMax) {
      nextPage = pageMax;
    }
    if (nextPage !== page) {
      onNavigate(nextPage);
    }
  }

  handlePrev() {
    const { page, displayType, onNavigate } = this.props;
    const subtraction = page > 2 && displayType === 'double' ? 2 : 1;
    let prevPage = page - subtraction;
    if (prevPage <= 0) {
      prevPage = 1;
    }
    if (prevPage !== page) {
      onNavigate(prevPage);
    }
  }

  /**
   * RENDER
   */
  render() {
    const { classes, page, pageMax, /*onNavigate,*/ fullscreen, onToggleFullscreen, onZoom } = this.props;
    const isMobile = !isNil(Device.mobile());
    
    if (fullscreen) {
      return (
        <Stack className={classes.fullscreenSingleButton} direction="row" spacing={1}>
          <IconButton onClick={this.handlePrev} disabled={page <= 1}>
            <Tooltip
              title="Page précédente"
              placement='top-start'
              arrow
            >
              <img className={classes.menuIcon} src={PrevIconUrl} alt="Page précédente" />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={() => onToggleFullscreen(false)}
            size="large"
          >
            <Tooltip
              title="Fermer le mode Plein écran"
              placement='top-end'
              arrow
            >
              <img className={classes.menuIcon} src={FullscreenCloseIconUrl} alt="Fermer le mode Plein écran" />
            </Tooltip>
          </IconButton>
          <IconButton onClick={this.handleNext} disabled={page === pageMax}>
            <Tooltip
              title="Page suivante"
              placement='top-start'
              arrow
              >
              <img className={classes.menuIcon} src={NextIconUrl} alt="Page suivante" />
            </Tooltip>
          </IconButton>
        </Stack>
      );
    }

    return (
      <Stack className={classes.navigation} direction="row" justifyContent="center" alignItems="center" spacing={4}>
        <Stack className={classes.navigationButtonContainer} direction="row" justifyContent="flex-end" spacing={2} >
          <IconButton onClick={this.handlePrev} disabled={page <= 1}>
            <Tooltip
              title="Page précédente"
              placement='top-start'
              arrow
            >
              <img className={classes.menuIcon} src={PrevIconUrl} alt="Page précédente" />
            </Tooltip>
          </IconButton>
        </Stack>
        {!isMobile && (
          <Slider
            className={classes.navigationProgressBar}
            color="primary"
            size="small"
            value={page}
            min={1}
            max={pageMax}
            marks={MARKS}
            step={1}
            valueLabelDisplay="auto"
            // onChange={(evt, value) => onNavigate(value)}
            disableSwap
            disabled
          />
        )}
        <Stack className={classes.navigationButtonContainer} direction="row" spacing={2} >
          <IconButton onClick={this.handleNext} disabled={page === pageMax}>
            <Tooltip
              title="Page suivante"
              placement='top-end'
              arrow
            >            
              <img className={classes.menuIcon} src={NextIconUrl} alt="Page suivante" />
            </Tooltip>
          </IconButton>
          {!isMobile && (
            <>
              <IconButton onClick={() => onZoom()}>
                <Tooltip
                  title="Double-clic sur le document pour zoomer"
                  placement='top'
                  arrow
                >
                  <img className={classes.menuIcon} src={ZoomIconUrl} alt="Zoomer" />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => onToggleFullscreen(true)}>
                <Tooltip
                  title="Mode plein écran"
                  placement='top'
                  arrow
                >
                  <img className={classes.menuIcon} src={FullscreenIconUrl} alt="Plein écran" />
                </Tooltip>
              </IconButton>
            </>
          )}
        </Stack>
      </Stack>
    );
  }
}

DocumentNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  // props
  page: PropTypes.number,
  pageMax: PropTypes.number,
  displayType: PropTypes.oneOf(['double', 'single']),
  fullscreen: PropTypes.bool,
  // funcs
  onNavigate: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
}

export default DocumentNavigation;
