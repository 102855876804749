import React from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';

import ShareIconUrl from '../../../../resources/icons/icon-share-white.svg';
import ShareIconMobileUrl from '../../../../resources/icons/icon-share-white.svg';

import { FACEBOOK_SHARE_PARAMS, X_SHARE_PARAMS, LINKEDIN_SHARE_PARAMS } from '../../../../config';

class ShareButton extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      shareAnchorEl: null,
      clipboardNotificationOpen: false,
    }
  }

  render() {
    const { classes, isMobile } = this.props;
    const { shareAnchorEl, clipboardNotificationOpen } = this.state;
    return (
      <>
        <ListItem key="share" className={classes.listItem} disablePadding sx={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
          <ListItemButton className={isMobile ? classes.shareAndDownloadMobileButton : null} onClick={evt => this.setState({ shareAnchorEl: evt.currentTarget })} sx={{ flexGrow: isMobile ? 0 : 1 }}>
            <ListItemIcon>
              <img className={classes.menuIcon} src={isMobile ? ShareIconMobileUrl : ShareIconUrl} alt="Partager le rapport" />
            </ListItemIcon>
            <ListItemText className={`${classes.drawerLabel} ${classes.whiteLabel}`} primary={<Typography variant="h4">Partager le rapport</Typography>} />
          </ListItemButton>
        </ListItem>
        <Popover
          className={classes.sharePopper}
          open={Boolean(shareAnchorEl)}
          anchorEl={shareAnchorEl}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'center'
          }}
          onClose={() => this.setState({ shareAnchorEl: null })}
          elevation={2}
        >
          <List disablePadding>
            {!isMobile && (
              <ListItem key="copy" disablePadding disableGutters>
                <ListItemButton onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(window.location.href);
                  } catch(e) {}
                  this.setState({ clipboardNotificationOpen: true, shareAnchorEl: null });
                }}>
                  <ListItemText className={classes.drawerLabel} primary={<Typography variant="h5">Copier le lien dans le presse-papier</Typography>} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem key="fb" disablePadding disableGutters>
              <ListItemButton onClick={() => { }}>
                <ListItemText className={classes.drawerLabel} primary={(
                  <a style={{ textDecoration: 'none' }} href={FACEBOOK_SHARE_PARAMS} target="_blank" rel="noopener noreferrer">
                    <Typography variant="h5">Partager sur Facebook</Typography>
                  </a>
                )} />
              </ListItemButton>
            </ListItem>
            <ListItem key="x" disablePadding disableGutters>
              <ListItemButton onClick={() => {
                window.open(X_SHARE_PARAMS, "pop", "width=600, height=400, scrollbars=no");
              }}>
                <ListItemText className={classes.drawerLabel} primary={<Typography variant="h5">Partager sur X</Typography>} />
              </ListItemButton>
            </ListItem>
            <ListItem key="linkedin" disablePadding disableGutters>
              <ListItemButton onClick={() => {
                window.open(LINKEDIN_SHARE_PARAMS, "pop", "width=600, height=400, scrollbars=no");
              }}>
                <ListItemText className={classes.drawerLabel} primary={<Typography variant="h5">Partager sur Linkedin</Typography>} />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
        <Snackbar
          open={clipboardNotificationOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ clipboardNotificationOpen: false })}
          message="Lien copié dans le presse papier"
        />
      </>
    );
  }
}

ShareButton.propTypes = {
  classes: PropTypes.object.isRequired,
  // props
  isMobile: PropTypes.bool,
};

export default ShareButton;
